
























































































































































































































































































































































































































































































































import { namespace } from 'vuex-class';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { decimalNumber, minValue, maxValue } from '@/misc/CustomValidators';
import Deal from '@/models/Deal.model';
import { DealStoreActions, DealStoreGetters } from '@/store/deal.store';
import { DateTime } from 'luxon';
import Category from '@/models/Category.model';
import draggable from 'vuedraggable';
import MediaFile from '@/interfaces/MediaFile.interface';
import { FileType } from '@/enum/FileType.enum';
import AssetFile from '@/interfaces/AssetFile.apiEntity';
import BaseMixin from '@/misc/BaseMixin.mixins';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';
import { ExpiryReason } from '@/enum/ExpiryReason.enum';
import { DisplayType } from '@/enum/DisplayType';

const DealStore = namespace('deals');

@Component({
  components: {
    draggable,
    CategoryTreeSelectComponent: () => import(
      /* webpackChunkName: "CategoryTreeSelectComponent" */
      '@/components/CategoryTreeSelect.component.vue'
    )
  },
  mixins: [validationMixin],
  validations: {
    strOldPrice: { decimalNumber },
    strPrice: { required, decimalNumber },
    strBudget: { decimalNumber, minValue: minValue(5), maxValue: maxValue(10000) },
    dealCopy: {
      title: { required },
      description: { required },
      category: { required },
    },
    startDate: {
      date: { required },
      hour: { required },
      minute: { required }
    },
    endDate: {
      date: { required: requiredIf((endDate) => endDate.hour || endDate.minute) },
      hour: { required: requiredIf((endDate) => endDate.date || endDate.minute) },
      minute: { required: requiredIf((endDate) => endDate.date || endDate.hour) }
    }
  }
})
export default class EditDealComponent extends mixins(ErrorMessageHandlerMixin, BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ default: () => undefined })
  public deal!: Deal;

  @Prop({ default: () => [] })
  public files!: MediaFile[];

  @Prop({ default: false })
  public isReactivating!: boolean;

  @DealStore.Action(DealStoreActions.CREATE)
  private createDealAction!: (payload: { deal: Deal, files?: File[] }) => Promise<Deal>;

  @DealStore.Action(DealStoreActions.UPDATE_WITH_FILES)
  private updateDealAction!: (payload: { deal: Deal, filesToAdd: File[], selectedFileObjs: AssetFile[] }) => Promise<Deal>;

  @DealStore.Action(DealStoreActions.REACTIVATE)
  private reactivateDealAction!: (payload: { deal: Deal, filesToAdd: File[], selectedFileObjs: AssetFile[] }) => Promise<Deal>;

  @DealStore.Action(DealStoreActions.GET_ALL_CATEGORIES)
  private getAllCategoriesAction!: () => Promise<Category>;

  @DealStore.Getter(DealStoreGetters.ALL_CATEGORIES)
  private allCategories!: Category[];

  private DisplayType = DisplayType;

  private dealCopy: Deal = new Deal();
  private isValid = true;
  private isLoading = false;
  private selectedStep: number = 1;

  // Index of currently active expansion panel:
  private showCategoryExpansionPanel: number = -1;

  // Contain prices as strings - are converted to number in watcher
  private strOldPrice?: string = '';
  private strPrice: string = '';
  private strBudget?: string = '';

  // Contains date and time separately for separate input components
  private startDate: { date: string | null, hour: string | null, minute: string | null } = { date: null, hour: null, minute: null };
  private endDate: { date: string | null, hour: string | null, minute: string | null } = { date: null, hour: null, minute: null };

  // Possible values for time:
  private hours: string[] = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', 
    '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
  ];
  private minutes: string[] = [
    '00', '15', '30', '45'
  ];

  // File objects for Vuetify component (only stored temporarily):
  private tempFilesToAdd: File[] = [];
  // File objects to additionally attach to deal copy:
  private filesToAdd: { file: File, url: string }[] = [];
  // URLs and type of all files that are currently attached to deal copy (used for preview):
  private selectedFiles: { id?: string, url?: string, type?: FileType, format?: string }[] = [];

  private acceptedMediaTypes = process.env.VUE_APP_CONFIG_ACCEPTED_MEDIA_TYPES;

  // Error message for enddate before startdate:
  private invalidEnddateMessage: string | null = null;

  private fileInputRules = [
    (files: File[]) => !files || 
      (
        // ( // Limit file size:
        //   !files.some(file => file.size > process.env.VUE_APP_CONFIG_MAX_FILE_SIZE) || 
        //   this.$t('GENERAL.VALIDATION_MESSAGES.MAX_FILE_SIZE', { size: `${process.env.VUE_APP_CONFIG_MAX_FILE_SIZE / 1e6}` })
        // ) &&
        ( // Limit filetypes:
          !files.some(file => this.acceptedMediaTypes.indexOf(file.type) == -1 ) ||
          this.$t('GENERAL.VALIDATION_MESSAGES.ACCEPTED_MEDIA_TYPE', { acceptedTypes: `${this.acceptedMediaTypes}` })
        )
      )
  ];

  private steps = [
    { title: 'DEAL.DIALOG.DESCRIPTION', validations: ['dealCopy.title', 'dealCopy.description'], complete: false, valid: true },
    { title: 'DEAL.DIALOG.MASTER_DATA', validations: ['dealCopy.category', 'strOldPrice', 'strPrice'], complete: false, valid: true },
    { title: 'DEAL.DIALOG.VALIDITY_PERIOD', validations: [
      'startDate.date', 'startDate.hour', 'startDate.minute', 'endDate.date', 'endDate.hour', 'endDate.minute'
      ], complete: false, valid: true },
    { title: 'DEAL.DIALOG.MEDIA', validations: [], complete: false, valid: true },
    { title: 'DEAL.DIALOG.BUDGET', validations: ['strBudget'], complete: false, valid: true },
  ];

  private minStartDate: string = DateTime.now().toFormat('yyyy-MM-dd');

  async created() {
    try {
      await this.getAllCategoriesAction();
    } catch (e) {
      this.handleAxiosError(e);
    }
  }

  @Watch('deal', { immediate: true })
  public async onDealChanged() {
    if (this.deal) {
      this.dealCopy = this.deal.copy() as Deal;

      if (this.isReactivating) {
        this.dealCopy.status = true;
        this.dealCopy.expiryReason = ExpiryReason.NOT_EXPIRED;
        this.dealCopy.startDate = undefined;
        this.dealCopy.endDate = undefined;
      }

      const startDate: DateTime | undefined = this.dealCopy.startDate ? DateTime.fromISO(this.dealCopy.startDate) : undefined;
      const endDate: DateTime | undefined = this.dealCopy.endDate ? DateTime.fromISO(this.dealCopy.endDate) : undefined;
      // Formats date to string for input-type date:
      if (startDate) {
        this.startDate.date = startDate.toFormat('yyyy-MM-dd');
        this.startDate.hour = startDate.toFormat('HH');
        this.startDate.minute = startDate.toFormat('mm');
      }
      
      if (endDate) {
        this.endDate.date = endDate.toFormat('yyyy-MM-dd');
        this.endDate.hour = endDate.toFormat('HH');
        this.endDate.minute = endDate.toFormat('mm');
      }

      this.strOldPrice = this.deal.oldPrice?.toLocaleString(undefined, { useGrouping: false, minimumFractionDigits: 2 });
      this.strPrice = this.deal.price?.toLocaleString(undefined, { useGrouping: false, minimumFractionDigits: 2 });
      this.strBudget = this.deal.budget?.toLocaleString(undefined, { useGrouping: false, minimumFractionDigits: 2 });

      if (this.isReactivating) {
        this.selectedStep = 3; // Switch to validity period
      }
      // Trigger validation check when editing:
      this.checkAllValidations();
    } else {
      this.dealCopy = new Deal(this.currentStore, this.currentUser);
    }
    this.isValid = !this.$v!.$invalid;
  }

  @Watch('files', { immediate: true })
  public onFilesChanged() {
    this.selectedFiles = [];
    this.filesToAdd = [];
    if (this.dealCopy.files) {
      for (const fileObj of this.dealCopy.files) {
        const file = this.files.find(file => file.fileId === fileObj.id);
        this.selectedFiles.push({ id: fileObj.id, url: file?.fileURL, type: file?.type, format: file?.format });
      }
    }
  }

  /**
   * Prevents linear and non-linear change of step if validation failed
   */
  @Watch('selectedStep', { immediate: true })
  public onStepChange(newVal: number, oldVal: number) {
    this.$nextTick(async () => {
      if (oldVal) {
        const isValid = await this.checkValidations(this.steps[oldVal - 1]) && (!this.invalidEnddateMessage || oldVal != 3);
        this.steps[oldVal - 1].valid = isValid;
        this.steps[oldVal - 1].complete = isValid;
        // If previous step is lower (before) current step
        // (preventing to move forward if validation fails) 
        if (oldVal < newVal) {
          this.nextStep(oldVal, newVal);
        }
      }
    });
  }

  /**
   * Parses string date and time to DateTime-object and saves object for dealCopy.
   */
  @Watch('startDate', { immediate: true, deep: true })
  private onStartDateChanged() {
    const date: DateTime = DateTime.fromFormat(
      this.startDate.date + ' ' + `${this.startDate.hour}:${this.startDate.minute}`, 'yyyy-MM-dd HH:mm'
    );
    
    if (this.dealCopy.endDate && DateTime.fromISO(this.dealCopy.endDate) < date) {
      this.invalidEnddateMessage = this.$t('DEAL.DIALOG.INVALID_END_DATE').toString();
      this.isValid = false;
    } else {
      this.invalidEnddateMessage = null;
    }
    this.dealCopy.startDate = date.toISO();
  }

  @Watch('startDate.date', { immediate: true }) 
  public onStartDateDateChanged() {
    if (this.startDate.date) {
      // set hour and minute initially to 0:
      if (!this.startDate.hour) {
        this.startDate.hour = '00';
      }
      if (!this.startDate.minute) {
        this.startDate.minute = '00';
      }
    }
  }

  @Watch('endDate', { immediate: true, deep: true })
  private onEndDateChanged() {
    const date: DateTime = DateTime.fromFormat(
      this.endDate.date + ' ' + `${this.endDate.hour}:${this.endDate.minute}`, 'yyyy-MM-dd HH:mm'
    );
    
    if (this.dealCopy.startDate && date < DateTime.fromISO(this.dealCopy.startDate)) {
      this.invalidEnddateMessage = this.$t('DEAL.DIALOG.INVALID_END_DATE').toString();
      this.isValid = false;
    } else {
      this.invalidEnddateMessage = null;
    }
    this.dealCopy.endDate = date.toISO();
  }

  @Watch('endDate.date', { immediate: true }) 
  public onEndDateDateChanged() {
    if (this.endDate.date) {
      // set hour and minute initially to 0:
      if (!this.endDate.hour) {
        this.endDate.hour = '00';
      }
      if (!this.endDate.minute) {
        this.endDate.minute = '00';
      }
    }
  }

  /**
   * Saves urls of newly added files and empties temporary storage for new files.
   */
  @Watch('tempFilesToAdd', { immediate: true })
  private onTempFilesToAddChanged() {
    if (this.tempFilesToAdd.length > 0) {
      // Save file urls in array:
      for (const file of this.tempFilesToAdd) {
        if (file.size < process.env.VUE_APP_CONFIG_MAX_FILE_SIZE && this.acceptedMediaTypes.indexOf(file.type) != -1) {
          const url = URL.createObjectURL(file);
          this.filesToAdd.push({ file: file, url: url });
          this.selectedFiles.push({ 
            url: url, 
            type: /video\/*/.test(file.type!) ? FileType.VIDEO : FileType.IMAGE, 
            format: file.type 
          });
          // Remove file from input field:
          this.tempFilesToAdd.splice(this.tempFilesToAdd.indexOf(file));
        }
      }
    }
  }

  /**
   * Saves value from input field component as number in dealCopy.
   *  
   * Input field component saves value as string, but is needed as number. 
   * Using v-model.number or type="number" does not work with validator, because 
   * it displays invalid value, but doesn't change the value of the model.
   */
  @Watch('strOldPrice', { immediate: true })
  private onStrOldPriceChanged() {
    this.dealCopy.oldPrice = this.strOldPrice ? parseFloat(this.strOldPrice.replace(',', '.')) : undefined;
  }

  @Watch('strPrice', { immediate: true })
  private onStrPriceChanged() {
    this.dealCopy.price = parseFloat(this.strPrice!.replace(',', '.'));
  }

  @Watch('strBudget', { immediate: true })
  private onStrBudgetChanged() {
    this.dealCopy.budget = this.strBudget ? parseFloat(this.strBudget.replace(',', '.')) : undefined;
  }

  /**
   * Moves from {currentStep} to {nextStep} in stepper if validation for {currentStep} succeeds.
   * @param currentStep Currently selected step.
   * @param nextStep Step to be switched to.
   */
  private async nextStep(currentStep: number = this.selectedStep, nextStep: number = this.selectedStep + 1) {
    const step = this.steps[currentStep - 1];
    const isValid = await this.checkValidations(step) && (!this.invalidEnddateMessage || currentStep != 3);
    step.valid = isValid;
    if (isValid) {
      this.selectedStep = nextStep;
    } else {
      this.selectedStep = currentStep;
    }
  }

  private removeFile(fileURL: string) {
    this.filesToAdd = this.filesToAdd.filter(file => file.url !== fileURL);
    this.selectedFiles = this.selectedFiles.filter(file => file?.url !== fileURL);
  }

  /**
   * Checks all validations for fields in given step.
   * @returns True if all validations are valid, else false.
   */
  private async checkValidations(step: any): Promise<boolean> {
    let isValid = true;
    for (let s of step.validations) {
      let valid = await this.checkForm(s);
      if (!valid) isValid = false;
    }
    step.valid = isValid;
    return isValid;
  }

  get strCategoryName(): string {
    return this.dealCopy.category ? this.$t(`DEAL.CATEGORIES.${(this.dealCopy.category as Category).name}`).toString() : '';
  }

  /**
   * Checks all validations of all steps.
   * Sets value for { this.isValid }.
   */
  private async checkAllValidations() {
    this.isValid = true;
    for (let step of this.steps) {
      if (!await this.checkValidations(step)) {
        this.isValid = false;
      }
    }
  }

  private async saveData() {
    await this.checkAllValidations();

    if (this.isValid && !this.isLoading) {
      try {
        this.isLoading = true;
        // Update expiry reason:
        if (this.dealCopy.expiryReason == ExpiryReason.NOT_EXPIRED && !this.dealCopy.status) {
          this.dealCopy.expiryReason = ExpiryReason.MANUALLY_DISABLED;
        }
        if (this.dealCopy.status) {
          this.dealCopy.expiryReason = ExpiryReason.NOT_EXPIRED;
        }
        // Remove old price if display time 'PERCENT' was chosen
        if (this.dealCopy.displayType == DisplayType.PERCENT) {
          this.dealCopy.oldPrice = undefined;
        }
        // Gather files to be uploaded
        const filesToAdd = this.filesToAdd.map(file => file.file);
        // Save deal
        if (this.deal && this.deal.id) { // Update
          // Filter selected files: consider only thoses files that were already uploaded
          const selectedFileObjs = this.selectedFiles.filter(file => file.id).map(file => {
            return { id: file.id!, type: file.type! };
          });
          if (this.isReactivating) {
            this.dealCopy = await this.reactivateDealAction({ deal: this.dealCopy, filesToAdd: filesToAdd, selectedFileObjs: selectedFileObjs });
          } else {
            this.dealCopy = await this.updateDealAction({ deal: this.dealCopy, filesToAdd: filesToAdd, selectedFileObjs: selectedFileObjs });
          }
        } else { // Create
          this.dealCopy = await this.createDealAction({ deal: this.dealCopy, files: filesToAdd });
        }
        this.dismiss(true);
      } catch (e) {
        this.handleAxiosError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }

  private async checkForm(type: string): Promise<boolean> {
    const inputValid = await this.triggerValidation(type);
    this.isValid = !this.$v!.$invalid;
    const affectedStep = this.steps.find(step => step.validations.indexOf(type) != -1);
    affectedStep!.valid = inputValid;
    return inputValid;
  }
}
