import { helpers } from "vuelidate/lib/validators";
import BaseMixin from './BaseMixin.mixins';

// Number with dot or comma as decimal separator
export const decimalNumber = (value: string) =>  !value || /^-?\d*(\,?|\.?)\d*$/.test(value);

// Minimum value validator that can handle decimals with comma as separator
export const minValue = (min: number) => helpers.withParams(
  { type: 'minValue', min: min },
  (value: string) => { 
    if (!value) return true;
    const numberVal: number = parseFloat(value.replace(',', '.'));
    return Math.floor(numberVal) >= min;
  }
);

// Maximum value validator that can handle decimals with comma as separator
export const maxValue = (max: number) => helpers.withParams(
  { type: 'maxValue', max: max },
  (value: string) => { 
    if (!value) return true;
    const numberVal: number = parseFloat(value.replace(',', '.'));
    return Math.floor(numberVal) <= max;
  }
);

// Url validator without required http:// oder https://
export const url = (value: string) => !value || BaseMixin.URL_REGEX.test(value);